<template>
  <img
    ref="img"
    :style="{ margin: '0 auto', objectFit: index === 0 ? 'fill' : 'contain' }"
  />
</template>
  <script>
export default {
  name: "AuthImg",
  props: {
    authSrc: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    Object.defineProperty(Image.prototype, "authsrc", {
      writable: true,
      enumerable: true,
      configurable: true,
    });
    let img = this.$refs.img;
    let request = new XMLHttpRequest();
    request.responseType = "blob";
    request.open("get", this.authSrc, true);
    // request.setRequestHeader("Authorization", "Bearer " + token); //根据后端需求添加的权限标志
    request.onreadystatechange = (e) => {
      if (request.readyState == XMLHttpRequest.DONE && request.status == 200) {
        img.src = URL.createObjectURL(request.response);
        img.onload = () => {
          URL.revokeObjectURL(img.src);
        };
      }
    };
    request.send(null);
  },
};
</script>
  
  